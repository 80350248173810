/*----------------------------------------------
	ページの一番上に戻る
---------------------------------------------*/

let pageTop = () => {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}

	//トップに戻るボタンの要素を取得
	let topButton = document.getElementById( 'page-top' );

	//ボタンの表示・非表示
	window.addEventListener ('scroll', () => {
		( getScrolled() > 500 ) ? topButton.classList.add( 'is_fade-in' ): topButton.classList.remove( 'is_fade-in' );
	});
	// window.onscroll = function() {
	//   ( getScrolled() > 500 ) ? topButton.classList.add( 'is_fade-in' ): topButton.classList.remove( 'is_fade-in' );
	// };

	//トップに移動する関数
	function scrollToTop() {
	  let scrolled = getScrolled();
	  // window.scrollTo( 0, Math.floor( scrolled / 1.1 ) );
		window.scrollTo( 0, parseInt( scrolled / 1.1 ) );
	  if ( scrolled > 0 ) {
	    window.setTimeout( scrollToTop, 5 );
	  }
	};

	//イベント登録
	topButton.onclick = function() {
	  scrollToTop();
	};
};

pageTop();

/*----------------------------------------------
	一選記事用フッター（問い合わせ先部分）
---------------------------------------------*/

let docHeight = document.body.scrollHeight; //ページ全体の高さ
let winHeight = window.innerHeight; //ウィンドウの高さ
let bottom = docHeight - winHeight; //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置

let fixedFooter = () => {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}

	//フッターの要素を取得
	let contactBox = document.getElementById( 'contact-box' );

	//フッターの表示・非表示
	if (contactBox) {
		window.addEventListener ('scroll', () => {
			( (200 < getScrolled()) && (getScrolled() < bottom * 0.9) ) ? contactBox.classList.add( 'is_fade-in' ): contactBox.classList.remove( 'is_fade-in' );
		});
	}
};

fixedFooter();

/*----------------------------------------------
	一選記事のスマホのみページトップボタンの位置を変更
---------------------------------------------*/

let winWidth = window.innerWidth //ウィンドウの幅
let target = document.querySelector('body');
let bodyId = target.id;
let topButton = document.getElementById ('page-top');

// CSS側でブレイクポイントを変更した場合は、以下の数字（600）も変更する必要あり
if (bodyId == 'pick-one' && winWidth < 600) {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}
	//ページトップボタンへのstyle属性の追加・削除
	window.addEventListener ('scroll', () => {
		if (getScrolled() < bottom * 0.9) {
			topButton.style.cssText = "bottom: 100px;";
		}
		else {
			topButton.removeAttribute('style');
		}
	});
};

/*----------------------------------------------
	ハンバーガーメニュークリックで、背面固定
---------------------------------------------*/

let navElem = document.getElementById('hamburger-nav');
let htmlElem = document.querySelector('HTML');
let bodyElem = document.querySelector('body');

navElem.addEventListener ('click', () => {
	htmlElem.classList.toggle('u_untouch');
	bodyElem.classList.toggle('u_untouch');
}, false);
